import { StandardEntity } from "./base/sys$StandardEntity";
import { ServiceType } from "../enums/enums";
export class Service extends StandardEntity {
  static NAME = "tagency_Service";
  type?: ServiceType | null;
  name?: string | null;
  active?: boolean | null;
  description?: string | null;
}
export type ServiceViewName = "_minimal" | "_local" | "_base";
export type ServiceView<V extends ServiceViewName> = V extends "_minimal"
  ? Pick<Service, "id" | "type" | "name">
  : V extends "_local"
  ? Pick<Service, "id" | "type" | "name" | "active" | "description">
  : V extends "_base"
  ? Pick<Service, "id" | "type" | "name" | "active" | "description">
  : never;
