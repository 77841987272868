import * as React from "react";
import logo from "./background.jpg";
import {Zoom} from 'react-awesome-reveal';

class HomePage extends React.Component {
  render() {
    return (
      <div style={{textAlign:'center', verticalAlign: 'middle'}}>
        <Zoom>
        <img src={logo} alt="Llyciah Logo" style={{
          objectFit: 'contain',
          fontFamily: 'object-fit: contain;',
          objectPosition: 'center',
          boxSizing: 'border-box',
          display: 'inline-block',
          width: '40%',
          height: '40%'
        }} />
        </Zoom>
      </div>
    );
  }
}

export default HomePage;
