import { StandardEntity } from "./base/sys$StandardEntity";
import { Booking } from "./tagency_Booking";
import { TransactionType } from "../enums/enums";
import { User } from "./base/sec$User";
import { Account } from "./tagency_Account";
import { Service } from "./tagency_Service";
import { AccountingDay } from "./tagency_AccountingDay";
export class Transction extends StandardEntity {
  static NAME = "tagency_Transction";
  label?: string | null;
  invoice?: string | null;
  booking?: Booking | null;
  description?: string | null;
  type?: TransactionType | null;
  amount?: any | null;
  marketer?: User | null;
  approved?: boolean | null;
  settlement?: Transction | null;
  debtor?: Account | null;
  creditor?: Account | null;
  service?: Service | null;
  reference?: string | null;
  valueDate?: AccountingDay | null;
  related?: Transction | null;
  deadline?: any | null;
}
export type TransctionViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "transction-customer-view"
  | "transction-view";
export type TransctionView<V extends TransctionViewName> = V extends "_minimal"
  ? Pick<Transction, "id" | "reference">
  : V extends "_local"
  ? Pick<
      Transction,
      | "id"
      | "label"
      | "invoice"
      | "description"
      | "type"
      | "amount"
      | "approved"
      | "reference"
      | "deadline"
    >
  : V extends "_base"
  ? Pick<
      Transction,
      | "id"
      | "reference"
      | "label"
      | "invoice"
      | "description"
      | "type"
      | "amount"
      | "approved"
      | "deadline"
    >
  : V extends "transction-customer-view"
  ? Pick<
      Transction,
      | "id"
      | "label"
      | "invoice"
      | "description"
      | "type"
      | "amount"
      | "approved"
      | "reference"
      | "deadline"
      | "booking"
      | "debtor"
      | "creditor"
      | "marketer"
      | "service"
      | "settlement"
      | "related"
      | "valueDate"
    >
  : V extends "transction-view"
  ? Pick<
      Transction,
      | "id"
      | "reference"
      | "label"
      | "invoice"
      | "description"
      | "type"
      | "amount"
      | "approved"
      | "deadline"
      | "debtor"
      | "creditor"
      | "marketer"
      | "service"
    >
  : never;
