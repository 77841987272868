import * as React from "react";
import { observer } from "mobx-react";
import { Price } from "../../cuba/entities/tagency_Price";
import { Card, Icon } from "antd";
import { collection, EntityProperty } from "@cuba-platform/react";

@observer
export class PriceCards extends React.Component {
  dataCollection = collection<Price>(Price.NAME, {
    view: "price-view",
    sort: "-updateTs"
  });
  fields = ["service", "description","amount"];

  render() {
    const { status, items } = this.dataCollection;

    if (status === "LOADING") {
      return <Icon type="spin" />;
    }

    return (
      <div className="narrow-layout">
        {items.map(e => (
          <Card
            title={e.name}
            key={e.id}
            style={{ marginBottom: "12px" }}
          >
            {this.fields.map(p => (
              <EntityProperty
                entityName={Price.NAME}
                propertyName={p}
                value={e[p]}
                key={p}
              />
            ))}
          </Card>
        ))}
      </div>
    );
  }
}
