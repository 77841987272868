import * as React from "react";

import "./Reset.css"
import {  Form, message, Alert, Input, Icon, Card, Button } from "antd";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { withLocalizedForm, MultilineText, clearFieldErrors, extractServerValidationErrors, constructFieldsWithErrors, injectMainStore, getCubaREST } from "@cuba-platform/react";
import { observable, action, IReactionDisposer, toJS } from "mobx";
import { observer } from "mobx-react";
import { FormComponentProps } from "antd/lib/form";
import { restServices } from "../../../cuba/services";

type Props = FormComponentProps & ResetProps;

type ResetProps = {
  close: (register: boolean) => void;
};

@observer
class Reset extends React.Component<WrappedComponentProps & Props> {


  resetService = (login: string) => {
    return restServices.tagency_RegisterService.resetPassword(getCubaREST()!)({ login: login });
  }

  fields = [
    "login"
  ];

  @observable
  submited = false;
  @observable
  globalErrors: string[] = [];
  reactionDisposer: IReactionDisposer;

  @action
  reset = () => {
    this.submited = true;
    this.props.form.validateFields((err) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }
      this.resetService(this.props.form.getFieldValue('login'))
        .then(() => {
          message.success(
            this.props.intl.formatMessage({ id: "reset.success" })
          );
          this.close();
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: response.details == null || response.details.length == 0 ? response.error : response.details
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
          this.submited = false;
        });
    });
  };
  close = () => this.props.close(false);

  render() {
    const { getFieldDecorator } = this.props.form;
    return (
      <Card title={<FormattedMessage id="reset.title" />} className="narrow-layout">
        <Form layout="horizontal" labelAlign="left" onSubmit={this.reset}>
          <Form.Item 
            style={{ marginBottom: "12px" }}>
            {getFieldDecorator('login', {
              rules: [{ required: true }]
            })(<Input placeholder={this.props.intl.formatMessage({
              id: "login.placeholder.login"
            })}
            prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large" />)}
          </Form.Item>
          {this.globalErrors.length > 0 && (
            <Alert
              message={<MultilineText lines={toJS(this.globalErrors)} />}
              type="error"
              style={{ marginBottom: "24px" }}
            />
          )}
          <Form.Item>

            <Button
              type="default"
              htmlType="button"
              style = {{float: "left"}}
              onClick={this.close}>
              <FormattedMessage id="management.editor.cancel" />
            </Button>
            <Button
              type="primary"
              htmlType="submit"
              disabled={this.submited}
              loading={this.submited}
              style={{ marginLeft: "8px", float: 'right' }}
            >
              <FormattedMessage id="management.editor.submit" />
            </Button>
          </Form.Item>
        </Form>
      </Card>);

  }
  /*
    componentDidMount() {
        this.dataInstance.setItem(new Customer());
      
      this.reactionDisposer = reaction(
        () => {
          return this.dataInstance.item;
        },
        () => {
          this.props.form.setFieldsValue(
            this.dataInstance.getFieldValues(this.fields)
          );
        }
      );
    }
  
    componentWillUnmount() {
      this.reactionDisposer();
    }*/
}

export default injectMainStore(injectIntl(
  withLocalizedForm<ResetProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(Reset))
);