import * as React from "react";
import { ChangeEvent, FormEvent } from "react";
import { Button, Form, Icon, Input, message } from "antd";
import { observer } from "mobx-react";
import { action, observable } from "mobx";
import { injectMainStore, MainStoreInjected } from "@cuba-platform/react";
import "./Login.css";
import logo from "./logo.png";
import { LanguageSwitcher } from "../../i18n/LanguageSwitcher";
import Centered from "../common/Centered";
import Powered from "../powered/Powered";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import Register from "./register/Register";
import Reset from "./reset/Reset";
import 'react-chat-widget/lib/styles.css';
import Widget from "../chat/components/widget";
import ConnectedWidget from "../chat/ConnectedWidget";




@injectMainStore
@observer
class Login extends React.Component<MainStoreInjected & WrappedComponentProps , {requestingRegister: boolean, requestingReset: boolean}> {

  state = {requestingRegister: false, requestingReset: false};
  @observable login: string;
  @observable password: string;
  @observable performingLoginRequest = false;
  //@observable requestingRegister = false;

  @action
  changeLogin = (e: ChangeEvent<HTMLInputElement>) => {
    this.login = e.target.value;
  };

  @action
  changePassword = (e: ChangeEvent<HTMLInputElement>) => {
    this.password = e.target.value;
  };

  
  register = ()=>{
    this.setState({requestingRegister: true});
  }

  reset = ()=>{
    this.setState({requestingReset: true});
  }

  @action
  doLogin = (e: FormEvent) => {
    e.preventDefault();
    this.performingLoginRequest = true;
    this.props
      .mainStore!.login(this.login, this.password)
      .then(
        action(() => {
          this.performingLoginRequest = false;
        })
      )
      .catch(
        action(() => {
          this.performingLoginRequest = false;
          message.error(this.props.intl.formatMessage({ id: "login.failed" }));
        })
      );
  };


handleClose = (register: boolean)=>{
  if (register)
    this.setState({requestingRegister: false});
  else
    this.setState({requestingReset:  false});
}

handleNewUserMessage = (msg:string) => {
    alert(msg);
  }
  render() {
    const {requestingRegister,requestingReset} = this.state;
    /*if (requestingRegister === true)
    {
      this.setState({requestingRegister: false});
    }
    if (requestingReset === true)
    {
      this.setState({requestingReset: false});
    }*/
      return (<div className="login-background">
      <Centered>
      <div className="login-form">
      <div className="login-title">
        <img
          src={logo}
          alt={this.props.intl.formatMessage({ id: "common.alt.logo" })}
          className="logo"
        />
        <div className="title"><FormattedMessage id="home.welcome" /> LLYCIAH Travel!</div>
        </div>
       
        {(requestingRegister || requestingReset) ?
          <>
          {requestingRegister ?
          <Register close={this.handleClose}/>: <Reset close={this.handleClose}/>}
          </>
          :
          <Form layout="vertical" onSubmit={this.doLogin}>
          <Form.Item>
            <Input
              placeholder={this.props.intl.formatMessage({
                id: "login.placeholder.login"
              })}
              onChange={this.changeLogin}
              value={this.login}
              prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <Input
              placeholder={this.props.intl.formatMessage({
                id: "login.placeholder.password"
              })}
              onChange={this.changePassword}
              value={this.password}
              type="password"
              prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
              size="large"
            />
          </Form.Item>
          <Form.Item>
            <div style={{ float: "right" }}>
              <LanguageSwitcher className="language-switcher" />
            </div>
          </Form.Item>
          <Form.Item>
            <Button
              type="primary"
              htmlType="submit"
              size="large"
              loading={this.performingLoginRequest}
            >
              <FormattedMessage id="login.loginBtn" />
            </Button>
            <Button 
                                                    type="link" 
                                                    size="large" 
                                                    htmlType="button" 
                                                    className='login-reset'  
                                                    onClick={this.reset}>
                                                      <FormattedMessage id="login.resetBtn"/>
                                                    </Button>
          </Form.Item>
          <div>
          <FormattedMessage id="login.register"/>
           </div>
           <Button
                                                    type="ghost" 
                                                    size="large" 
                                                    htmlType="button" 
                                                    className='login-register'  
                                                    onClick={this.register}>
                                                      <FormattedMessage id="login.registerBtn"/>
                                                    </Button>
              </Form>
        }
        <Powered/>
      </div>
      </Centered>
      </div>
    );
  }

  componentDidMount()
  {
    //setInterval(()=>addResponseMessage("bonh"),1000);
    //setInterval(()=>renderCustomComponent(Test,{test: true},true),1001);
  }
/*
  shouldComponentUpdate(nextProps: any, nextState: any)
  {
    if ( nextState.requestingRegister != this.state.requestingRegister)
      return nextState.requestingRegister && !this.state.requestingRegister;
    else if( nextState.requestingReset != this.state.requestingReset)
      return nextState.requestingReset && !this.state.requestingReset;
    else
      return true;
  }*/
}

export default injectIntl(Login);

