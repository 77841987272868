import React from 'react';
import PropTypes from 'prop-types';

import Header from './components/Header';
import Messages from './components/Messages';
import Sender from './components/Sender';
import QuickButtons from './components/QuickButtons';
import './style.scss';

type ConversationProps =
{

    title: string,
    titleAvatar: string,
    subtitle: string,
    sendMessage: ()=>{},
    senderPlaceHolder:string,
    profileAvatar: string,
    toggleChat: ()=>{},
    showCloseButton: boolean,
    disabledInput: boolean,
    autofocus: boolean,
    onQuickButtonClicked: ()=>{}
}

class Conversation  extends React.Component<ConversationProps>
{
  render()
  {
    return(
  <div className="rcw-conversation-container">
    <Header
      title={this.props.title}
      subtitle={this.props.subtitle}
      toggleChat={this.props.toggleChat}
      showCloseButton={this.props.showCloseButton}
      titleAvatar={this.props.titleAvatar}
    />
    <Messages
      profileAvatar={this.props.profileAvatar}
    />
    <QuickButtons onQuickButtonClicked={this.props.onQuickButtonClicked} />
    <Sender
      sendMessage={this.props.sendMessage}
      placeholder={this.props.senderPlaceHolder}
      disabledInput={this.props.disabledInput}
      autofocus={this.props.autofocus}
    />
  </div>);
  }
}

export default Conversation;
