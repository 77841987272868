export enum AccountType {
  Customer = "Customer",
  Bank = "Bank",
  Main = "Main",
  Provider = "Provider",
  Other = "Other"
}

export enum AppointmentStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  ACCEPTED = "ACCEPTED",
  REFUSED = "REFUSED"
}

export enum BookingStatus {
  DRAFT = "DRAFT",
  SUBMITTED = "SUBMITTED",
  APPROVED = "APPROVED",
  REFUSED = "REFUSED",
  PROCESSING = "PROCESSING",
  COMPLETED = "COMPLETED"
}

export enum DocumentType {
  CUSTOMER = "CUSTOMER",
  EMPLOYEE = "EMPLOYEE",
  GENERAL_INSTRUCTION = "GENERAL_INSTRUCTION",
  PROCEDURE_MANUAL = "PROCEDURE_MANUAL",
  PRINTED = "PRINTED",
  VISA_APPLICATION = "VISA_APPLICATION"
}

export enum ServiceType {
  FLY = "FLY",
  TOURISM = "TOURISM",
  INSURANCE = "INSURANCE",
  HOTEL = "HOTEL",
  CAR = "CAR",
  VISA = "VISA",
  PvcCard = "PvcCard",
  Hostess = "Hostess"
}

export enum Status {
  NEW = "NEW",
  DRAFT = "DRAFT",
  WAIT_DELETION = "WAIT_DELETION",
  DELETED = "DELETED",
  WAIT_ACTIVATION = "WAIT_ACTIVATION",
  ACTIVE = "ACTIVE",
  WAIT_INACTIVATION = "WAIT_INACTIVATION",
  INACTIVE = "INACTIVE",
  UPDATED = "UPDATED"
}

export enum TransactionType {
  Deposit = "Deposit",
  Settlement = "Settlement",
  Withdraw = "Withdraw",
  Other = "Other",
  Purchase = "Purchase",
  Customer = "Customer",
  Reversal = "Reversal",
  Liquidity = "Liquidity",
  Balance = "Balance"
}

export enum VisitorStatus {
  WAIT = "WAIT",
  APPROVED = "APPROVED",
  RECEIVED = "RECEIVED",
  END = "END"
}
