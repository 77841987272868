import React from 'react';
import { connect } from 'react-redux';

import Launcher from './components/Launcher';
import './style.scss';
import Conversation from './components/Conversation/Conversation';
type WidgetLayoutProps = {
  title: string,
  titleAvatar: string,
  subtitle: string,
  onSendMessage: (...args: any[])=>any,
  onToggleConversation:(...args: any[])=>any,
  showChat: boolean,
  senderPlaceHolder: string,
  onQuickButtonClicked: (...args: any[])=>any,
  profileAvatar: string,
  showCloseButton: boolean,
  disabledInput: boolean,
  fullScreenMode: boolean,
  badge: number,
  autofocus: boolean,
  connected: boolean,
  customLauncher: (...args: any[])=>any
};
class WidgetLayout extends React.Component<WidgetLayoutProps>
{
  render(){
    return (
  <div
    className={
      `rcw-widget-container ${this.props.fullScreenMode ? 'rcw-full-screen' : ''} ${this.props.showChat ? 'rcw-opened' : ''}`
    }
  >
    {this.props.showChat &&
      <Conversation
        title={this.props.title}
        subtitle={this.props.subtitle}
        sendMessage={this.props.onSendMessage}
        senderPlaceHolder={this.props.senderPlaceHolder}
        onQuickButtonClicked={this.props.onQuickButtonClicked}
        profileAvatar={this.props.profileAvatar}
        toggleChat={this.props.onToggleConversation}
        showCloseButton={this.props.showCloseButton}
        disabledInput={this.props.disabledInput}
        autofocus={this.props.autofocus}
        titleAvatar={this.props.titleAvatar}
      />
    }
    {this.props.customLauncher ?
      this.props.customLauncher(this.props.onToggleConversation) :
      !this.props.fullScreenMode &&
      <Launcher
        toggle={this.props.onToggleConversation}
        badge={this.props.badge}
      />
    }
  </div>
);
  }
}



export default connect(store => ({
  showChat: (store as any).behavior.get('showChat'),
  disabledInput: (store as any).behavior.get('disabledInput')
}))(WidgetLayout);
