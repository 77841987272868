import * as React from "react";
import "./App.css";
import { Icon, Layout, Menu } from "antd";
import { observer } from "mobx-react";
import Login from "./login/Login";
import Centered from "./common/Centered";
import AppHeader from "./header/AppHeader";
import { NavLink, Route, Switch } from "react-router-dom";
import HomePage from "./home/HomePage";
import { menuItems } from "../routing";
import {
  injectMainStore,
  MainStoreInjected,
  RouteItem,
  SubMenu,
  getCubaREST
} from "@cuba-platform/react";
import { CenteredLoader } from "./CenteredLoader";
import {
  FormattedMessage,
  injectIntl,
  IntlFormatters,
  WrappedComponentProps
} from "react-intl";
import Powered from "./powered/Powered";
import { restServices } from "../cuba/services";
import { observable, action } from "mobx";
import { ChatMessage } from "../cuba/entities/tagency_ChatMessage";
import { addUserMessage, addResponseMessage, isWidgetOpened, disableInput,enableInput, notifyConnected, notifyDisconnected } from "./chat/store/dispatcher";
import ConnectedWidget from "./chat/ConnectedWidget";
import store  from './chat/store/store';


class Message
{
  id: string;
  content: string;
}
@injectMainStore
@observer
class AppComponent extends React.Component<
  MainStoreInjected & WrappedComponentProps
> {

  onUserMessage = (msg: string)=>{
    restServices.tagency_ChatService.sendMessage(getCubaREST()!)({groupId: this.chatGroupId,content: msg})
    .then((cMsg : ChatMessage)=> addUserMessage(msg))
    .catch((er)=>console.error(er))
  }

@observable
 chatGroupId :any = null;

 @observable
 markerterIsOnline: boolean;

 @observable
 unreadeMsg: number=0;

 @observable
chatData = {subtitle: '',
            badge: 0,
            opened: false};
  
  connectedCount: number = 0;

onQuickButtonClicked = (value:any ) => console.debug('click '+value)

 //@action
 loadGroupId = ()=> restServices.tagency_ChatService.getConnectedCustomerGroup(getCubaREST()!)().then((id)=>{
   this.chatGroupId = id;
   enableInput();
 }).catch((er)=>console.error(er))

  render() {
    const mainStore = this.props.mainStore!;
    const { initialized, locale, loginRequired } = mainStore;

    if (!initialized || !locale) {
      if (this.chatGroupId !== null)
        this.chatGroupId = null;
      return <CenteredLoader />;
    }

    if (loginRequired) {
      if (this.chatGroupId !== null)
        this.chatGroupId = null;
      return (
        <Centered>
          <Login />
        </Centered>
      );
    }
    this.loadGroupId();
    const menuIdx = 1;

    return (
      <Layout className="main-layout">
        <Layout.Header>
          <AppHeader />
        </Layout.Header>
        <Layout>
          <Layout.Sider
            width={200}
            breakpoint="sm"
            collapsedWidth={0}
            style={{ background: "#fff" }}
          >
            <Menu mode="inline" style={{ height: "100%", borderRight: 0 }}>
              <Menu.Item key={menuIdx}>
                <NavLink to={"/"}>
                  <Icon type="home" />
                  <FormattedMessage id="router.home" />
                </NavLink>
              </Menu.Item>
              {menuItems.map((item, idx) =>
                menuItem(item, "" + (idx + 1 + menuIdx), this.props.intl)
              )}
            </Menu>
          </Layout.Sider>
          <Layout style={{ padding: "24px 24px 24px" }}>
            <Layout.Content>
              <Switch>
                <Route exact={true} path="/" component={HomePage} />
                {collectRouteItems(menuItems).map(route => (
                  <Route
                    key={route.pathPattern}
                    path={route.pathPattern}
                    component={route.component}
                  />
                ))}
              </Switch>
              <ConnectedWidget
                store={store}
                title={this.props.intl.formatMessage({id: 'chat.title'})}
                data = {this.chatData}
                
                  senderPlaceHolder= { this.props.intl.formatMessage({id: 'chat.placeHolder'})}
                  showCloseButton= {true}
                  fullScreenMode= {false}
                
                  autofocus= {true}
                  handleQuickButtonClicked = {this.onQuickButtonClicked}
               handleNewUserMessage={this.onUserMessage}/>
            </Layout.Content>
            <Layout>
              <Layout.Footer><Powered/></Layout.Footer>
            </Layout>
            
           
          </Layout>
        </Layout>
      </Layout>
    );
  }

//@action
checkMarketerOnline = ()=>
{
  const cuba = getCubaREST()!;
  const COUNT_LIMIT = 2;
  restServices.tagency_ChatService.isConnectedCustomerMarketerOnline(cuba)()
    .then((value: string)=>{
      const connected: boolean = JSON.parse(value) as boolean;
    /* if(connected && !this.chatData.opened)
     {
       this.connectedCount = this.connectedCount % COUNT_LIMIT +1;
       if (this.connectedCount == 1)
        notifyConnected();
     }*/
      //console.debug("connected "+connected+ " typ"+typeof connected)
      //console.debug("market "+this.markerterIsOnline)
       if (this.markerterIsOnline !== connected)
       {
          this.markerterIsOnline = connected;
          //console.debug('timeer '+this.messageTimer);
          //console.debug('onilne '+this.markerterIsOnline);
          let subtitle: string ;
          //store.dispatch(toggleInputDisabled());
          if (this.markerterIsOnline)
          {
            //this.chatData.reminder++;
            //enableInput();
            subtitle = 'chat.marketer.online';
            if (this.messageTimer == undefined)
              this.messageTimer = setInterval(()=>this.loadMessages(),6000);
          }
          else
          {
           // notifyDisconnected();
            //this.chatData.reminder=0;
            //disableInput();
            subtitle ='chat.marketer.offline';
            if (this.messageTimer != undefined)
            {
                clearInterval(this.messageTimer);
                this.messageTimer =undefined;
            }
          }
          this.chatData.subtitle = this.props.intl.formatMessage({id: subtitle});
       }
    })
    .catch((er)=>console.error(er));
}

  messageIds: Array<string> = [];

  @action
  loadMessages = ()=> {
    const cuba = getCubaREST()!;
    restServices.tagency_ChatService.loadNewMessages(cuba)({groupId: this.chatGroupId, limit: 10})
    .then((resp: string)=>{
      const msgs:Array<Message>= JSON.parse(resp) ;
    msgs.forEach((msg)=>{
      if (this.messageIds.indexOf(msg.id)== -1)
      {
        this.messageIds.push(msg.id);
        addResponseMessage(msg.content);
        if (!this.chatData.opened)
          this.chatData.badge++;
      }
      //console.debug('store '+);
        //if(this.messageIds.indexOf(msg.id)===-1)
        //showChat
        
        //console.debug(`message id ${msg.id}`)
       restServices.tagency_ChatService.markAsRead(cuba)({messageIds: [msg.id]})
      });
      //console.debug(`mesages id ${msgs.keys()}`)
    })
    .catch((er)=>console.error(er))
  }


messageTimer : any;
onlineTimer : any;

unsubscribeStore:()=>void;
  componentDidMount()
  {
    this.onlineTimer = setInterval(()=>{
      if (this.chatGroupId !== null)
        this.checkMarketerOnline();
    },30000);
    this.unsubscribeStore= store.subscribe(()=>{
      const opened  = isWidgetOpened();
     //console.debug(`event ${store.getState().behavior}`);
      if (this.chatData.opened != opened)
      {
        this.chatData.opened = opened;
        if (opened)
          this.chatData.badge=0;
      }
    });
    disableInput();
    //store.dispatch(toggleInputDisabled());
  }

  componentWillUnmount()
  {
    //clearInterval(this.messageTimer);
    clearInterval(this.onlineTimer);
    this.unsubscribeStore();
  }
}



function menuItem(
  item: RouteItem | SubMenu,
  keyString: string,
  intl: IntlFormatters
) {
  // Sub Menu

  if ((item as any).items != null) {
    //recursively walk through sub menus
    return (
      <Menu.SubMenu
        key={keyString}
        title={intl.formatMessage({
          id: "router." + item.caption
        })}
      >
        {(item as SubMenu).items.map((ri, index) =>
          menuItem(ri, keyString + "-" + (index + 1), intl)
        )}
      </Menu.SubMenu>
    );
  }

  // Route Item

  const { menuLink } = item as RouteItem;

  return (
    <Menu.Item key={keyString}>
      <NavLink to={menuLink}>
        <Icon type="bars" />
        <FormattedMessage id={"router." + item.caption} />
      </NavLink>
    </Menu.Item>
  );

}



function collectRouteItems(items: Array<RouteItem | SubMenu>): RouteItem[] {
  return items.reduce(
    (acc, curr) => {
      if ((curr as SubMenu).items == null) {
        // Route item
        acc.push(curr as RouteItem);
      } else {
        // Items from sub menu
        acc.push(...collectRouteItems((curr as SubMenu).items));
      }
      return acc;
    },
    [] as Array<RouteItem>
  );
}




const App = injectIntl(AppComponent);
export default App;
