import * as React from "react";
import { FormEvent } from "react";
import { Alert, Button, Card, Form, message, Modal, Cascader } from "antd";
import { observer } from "mobx-react";
import { BookingManagement } from "./BookingManagement";
import { FormComponentProps } from "antd/lib/form";
import { Link, Redirect } from "react-router-dom";
import { IReactionDisposer, observable, reaction, toJS, action } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  collection,
  Field,
  instance,
  withLocalizedForm,
  extractServerValidationErrors,
  constructFieldsWithErrors,
  clearFieldErrors,
  MultilineText,
  EntityProperty,
  Msg,
  MainStoreInjected,
  injectMainStore,
  getPropertyInfoNN,
  getEnumCaption,
  DataCollectionStore
} from "@cuba-platform/react";

import "../../app/App.css";
import { Booking } from "../../cuba/entities/tagency_Booking";

import { Service } from "../../cuba/entities/tagency_Service";

import { Branch } from "../../cuba/entities/tagency_Branch";
import TextArea from "antd/lib/input/TextArea";
import {  MetaPropertyInfo } from "@cuba-platform/rest";
import { BookingStatus, ServiceType } from "../../cuba/enums/enums";
import { CascaderOptionType, FilledFieldNamesType } from "antd/lib/cascader";

type Props = FormComponentProps & EditorProps;

type EditorProps = {
  entityId: string;
  readonly: boolean;
};



class ReadOnlyView extends React.Component<{ booking: any }>
{
  fields = ["createTs", 'reason', "information", "service", "branch", "status"];
  render() {
    const data = this.props.booking;
    if (data === undefined)
      return null;
    return (<div><>
      {data.status !== BookingStatus.DRAFT && <EntityProperty
        entityName={Booking.NAME}
        propertyName='code'
        value={data['code']}
        key='code'
      />}
    </>
      <>{this.fields.map(p => (
        <EntityProperty
          entityName={Booking.NAME}
          propertyName={p}
          value={data[p]}
          key={p}
        />))}
      </>
      <>
        {data.status === BookingStatus.COMPLETED && <EntityProperty
          entityName={Booking.NAME}
          propertyName='transaction'
          value={data['transaction']}
          key='transaction'
        />}
      </>
    </div>);
  }
}

class CloseButton extends React.Component<{ lableId: string }>
{
  render() {
    return (<Link to={BookingManagement.PATH}>
      <Button htmlType="button">
        <FormattedMessage id={this.props.lableId} />
      </Button>
    </Link>);
  }
}

type ServiceCascaderProps =
{dataCollection: DataCollectionStore<Service>
  , onChange?: (event: any)=>any | undefined,
  value?: any};


@injectMainStore
@observer
class ServiceCascader extends React.Component<ServiceCascaderProps& MainStoreInjected>
  {
    @observable
    dataCollection = this.props.dataCollection ;

    onChange = (value: any)=>
    {
      if (this.props.onChange)
      this.props.onChange({id:  value[1]});
    }

    render()
    {
      const {dataCollection }= this;
      if (dataCollection.status === "DONE")
      {
        let value = this.props.value;
        if (value)
        {
          if (value.id)  
            value = value.id;
          value = dataCollection.items.find(serv => serv.id == value);
            if (value)
              value = [value.type,value.id];
        }
        return (<Cascader   showSearch= {{filter: this.filter}} value={value}
        options={this.buildServierTree()}
        onChange ={this.onChange}/>)
      }
      else
      return null;
  }
    buildServierTree = (): CascaderOptionType[] => {
      const propertyInfo: MetaPropertyInfo = getPropertyInfoNN('type',Service.NAME,this.props.mainStore!.metadata!);
      const {enums} = this.props.mainStore!;
      let data = {};
      //this.servicesDc.load();
      this.dataCollection.items!.forEach((obj)=>{
        const type: ServiceType = obj.type!;
        let services: Array<Service> = data[type];
        if (services === undefined)
        {
            services = [obj];
            data[type] = services ;
        }
        else
          services.push(obj);
      });
      let tree: CascaderOptionType[] = [];
      Object.keys(data).forEach((typ: ServiceType)=>{
          let childs = [];
          const items = data[typ];
          for(let index  in items)
          {
            const item =items[index];
            //console.debug(item);
              childs.push({value: item.id, label: item.name});
          }
          tree.push({label: getEnumCaption(typ,propertyInfo,enums!)!, value: typ, children: childs});
      });
      return tree;
    }
    
    filter =(inputValue: string, path: CascaderOptionType[], names: FilledFieldNamesType):boolean =>  {
      return  path.some(option => (option.label as string).toLowerCase().indexOf(inputValue.toLowerCase()) > -1);
    }
}

@observer
@injectMainStore
class BookingEditComponent extends React.Component<
Props & WrappedComponentProps & MainStoreInjected ,{updated: boolean}
> {
  dataInstance = instance<Booking>(Booking.NAME, {
    view: "booking-customer-view",
    loadImmediately: false
  });
constructor(props: any)
{
  super(props);
  this.state= {updated: false};
}
  servicesDc = collection<Service>(Service.NAME, { view: "_minimal" });

  branchsDc = collection<Branch>(Branch.NAME, { view: "_minimal" });


update = ()=>this.setState({updated: true});
  reactionDisposer: IReactionDisposer;

  fields = ['reason', "information", "service", "branch"];

  @observable
  globalErrors: string[] = [];

  handleSubmit = (e: FormEvent) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        return;
      }
      this.dataInstance
        .update(this.props.form.getFieldsValue(this.fields))
        .then(() => {
          message.success(
            this.props.intl.formatMessage({ id: "management.editor.success" })
          );
          this.update();
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.error"
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
        });
    });
  };



  @observable
  submited = false;

  @action
  confirm = () => {let dialog=
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "booking.submit.areYouSure" },
        { instanceName: this.dataInstance.item!._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "booking.submit.areYouSure.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "booking.submit.areYouSure.cancel"
      }),
      onOk: () => {
        this.submited = true;
        return this.dataInstance.update({ status: BookingStatus.SUBMITTED })
          .then(() => {
            message.success(
              this.props.intl.formatMessage({ id: "booking.success" })
            );
            this.update();
          })
          .catch(() => {
            message.error(
              this.props.intl.formatMessage({ id: "booking.error" }));
            this.submited = false;
          }
          )
      }
    });
  };



//loading={status === "LOADING"}
  render() {
    //console.debug('sttus '+this.dataInstance.status);
    if (this.state.updated === true) {
      return <Redirect to={BookingManagement.PATH} />;
    }
    const { status } = this.dataInstance;
    const item = this.dataInstance.item!;
    const showSubmit = status === "DONE" && item.status === BookingStatus.DRAFT;
    return (<Card className="narrow-layout" >
      {!this.props.readonly ?
        <Form onSubmit={this.handleSubmit} layout="vertical" >
          <Field
            entityName={Booking.NAME}
            propertyName="reason"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" }, }}
            getFieldDecoratorOpts={{
              rules: [{ required: true, max: 20 }]
            }}
          />
          <Form.Item label={<Msg propertyName='information' entityName={Booking.NAME} key='information' />}
            style={{ marginBottom: "12px" }}>
            {this.props.form.getFieldDecorator('information')(<TextArea />)}
          </Form.Item>
           <Form.Item label={<Msg propertyName='service' entityName={Booking.NAME} key='service' />}
            style={{ marginBottom: "12px" }} >
            {this.props.form.getFieldDecorator('service',{
              rules: [{required: true}]
            })(<ServiceCascader dataCollection={this.servicesDc}  />)}
          </Form.Item>

          <Field
            entityName={Booking.NAME}
            propertyName="branch"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" } }}
            optionsContainer={this.branchsDc}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />

          {this.globalErrors.length > 0 && (
            <Alert
              message={<MultilineText lines={toJS(this.globalErrors)} />}
              type="error"
              style={{ marginBottom: "24px" }}
            />
          )}

          <Form.Item style={{ textAlign: "center" }}>
            <CloseButton lableId="management.editor.cancel" />
            <Button
              type="primary"
              htmlType="submit"
              disabled={status !== "DONE" && status !== "ERROR"}
              loading={status === "LOADING"}
              style={{ marginLeft: "8px" }}
            >
              <FormattedMessage id="management.editor.submit" />
            </Button>
          </Form.Item>
        </Form>
        :
        <div>
          <ReadOnlyView booking={item} />
          <div style={{ marginTop: "12px" }}><CloseButton lableId="management.editor.close" />
            <Button
              hidden={!showSubmit}
              loading={this.submited}
              type="primary"
              htmlType="button"
              style={{ marginLeft: "8px" }}
              onClick={this.confirm}
            >
              <FormattedMessage id="booking.submit" />
            </Button>
          </div>
        </div>
      }
    </Card>

    );
  }

  componentDidMount() {
    if (this.props.entityId !== BookingManagement.NEW_SUBPATH) {
        this.dataInstance.load(this.props.entityId);
    } else {
      this.dataInstance.setItem(new Booking());
    }
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      () => {
        this.props.form.setFieldsValue(
          this.dataInstance.getFieldValues(this.fields)
        );
      }
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }
}
/*
<Field
            entityName={Booking.NAME}
            propertyName="service"
            form={this.props.form}
            formItemOpts={{ style: { marginBottom: "12px" }, }}
            optionsContainer={this.servicesDc}
            getFieldDecoratorOpts={{
              rules: [{ required: true }]
            }}
          />
          */
export default injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(BookingEditComponent)
);

