import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import send from '../../../../../assets/send_button.svg';

import './style.scss';
import { Observer } from 'mobx-react';
import { observable, action } from 'mobx';

class Sender extends Component{
  constructor(props)
  {
    super(props);
    this.state = {buttonDisabled: true};
  }
  
  input = React.createRef();

  componentDidUpdate() {
    this.input.current.focus();
  }


onChange = (event)=>{
  const content = event.target.value;
  if (content != null && content.trim().length>0)
  {
    if (this.state.buttonDisabled)
      this.toggeButtonDisabled();
  }
  else
  {
    if (!this.state.buttonDisabled)
      this.toggeButtonDisabled();
  }
};

toggeButtonDisabled = ()=> this.setState((state,props)=>({buttonDisabled: !state.buttonDisabled}));

onSubmit = (event)=>{
  this.toggeButtonDisabled();
  this.props.sendMessage(event);
};

  render() {
    const { placeholder, disabledInput, autofocus } = this.props;
    return (
      <form className="rcw-sender" onSubmit={this.onSubmit}>
        <textarea type="text" className="rcw-new-message" name="message" onChange={this.onChange} placeholder={placeholder} disabled={disabledInput} autoFocus={autofocus} autoComplete="off" ref={this.input}/>
        <button type="submit" className="rcw-send" disabled={this.state.buttonDisabled}>
          <img src={send} className="rcw-send-icon" alt="send" />
        </button>
      </form>
    );
  }

}

Sender.propTypes = {
  sendMessage: PropTypes.func,
  placeholder: PropTypes.string,
  disabledInput: PropTypes.bool,
  autofocus: PropTypes.bool,
  //disabledButton: PropTypes.bool
};

export default (Sender);
