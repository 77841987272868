import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import BookingEdit from "./BookingEdit";
import BookingList from "./BookingList";

type Props = RouteComponentProps<{ entityId?: string ,action: string}>;

@observer
export class BookingManagement extends React.Component<Props> {
  static PATH = "/bookingManagement";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId,action } = this.props.match.params;
    return (
      <>{entityId ? <BookingEdit readonly={action ==="view"} entityId={entityId} /> : <BookingList />}</>
    );
  }
}
