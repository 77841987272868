import * as React from "react";

import { observer } from "mobx-react";

import { List, Icon, Spin } from "antd";

import { Transction } from "../../cuba/entities/tagency_Transction";
import { Link } from "react-router-dom";

import {
  collection,
  injectMainStore,
  MainStoreInjected,
  EntityProperty
} from "@cuba-platform/react";

import { TransctionManagement } from "./TransctionManagement";
import {
  injectIntl,
  WrappedComponentProps
} from "react-intl";

@injectMainStore
@observer
class TransctionListComponent extends React.Component<
  MainStoreInjected & WrappedComponentProps
> {
  dataCollection = collection<Transction>(Transction.NAME, {
    view: "transction-view",
    sort: "-updateTs"
  });

  fields = [
    'createdTs',
    'valueDate',
    "reference",
    "label",

    "type",
    "service",

    "amount"

  ];



  render() {
    const { status, items } = this.dataCollection;

    if (status === "LOADING") {
      return (
        <div
          style={{
            position: "absolute",
            left: "50%",
            top: "50%",
            transform: "translate(-50%, -50%)"
          }}
        >
          <Spin size="large" />
        </div>
      );
    }

    return (
      <div className="narrow-layout">
        <List
          itemLayout="horizontal"
          bordered
          dataSource={items}
          renderItem={item => (
            <List.Item
              actions={[
                <Link hidden={true} to={TransctionManagement.PATH + "/" + item.id} key="view">
                  <Icon type="read" />
                </Link>
              ]}
            >
              <div style={{ flexGrow: 1 }}>
                {this.fields.map(p => (
                  <EntityProperty
                    entityName={Transction.NAME}
                    propertyName={p}
                    value={item[p]}
                    key={p}
                  />
                ))}
              </div>
            </List.Item>
          )}
        />
      </div>
    );
  }
}

const TransctionList = injectIntl(TransctionListComponent);

export default TransctionList;
