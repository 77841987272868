import * as React from "react";
import "./Powered.css"
class Powered extends React.Component {
  render() {
    return(
    <div className='c-powered-by'>
      Powered by<a href='https://softteam.tech'>SoftTeam tech</a>
      </div>);
  }
}
 export default Powered;