import { StandardEntity } from "./base/sys$StandardEntity";
import { User } from "./tagency$User";
import { Account } from "./tagency_Account";
export class Branch extends StandardEntity {
  static NAME = "tagency_Branch";
  name?: string | null;
  location?: string | null;
  users?: User[] | null;
  account?: Account | null;
  active?: boolean | null;
  approved?: boolean | null;
}
export type BranchViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "branch-view"
  | "branch-account";
export type BranchView<V extends BranchViewName> = V extends "_minimal"
  ? Pick<Branch, "id" | "name">
  : V extends "_local"
  ? Pick<Branch, "id" | "name" | "location" | "active" | "approved">
  : V extends "_base"
  ? Pick<Branch, "id" | "name" | "location" | "active" | "approved">
  : V extends "branch-view"
  ? Pick<Branch, "id" | "name" | "location" | "active" | "approved" | "users">
  : V extends "branch-account"
  ? Pick<Branch, "id" | "name" | "location" | "active" | "approved" | "account">
  : never;
