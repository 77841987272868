import * as React from "react";

import "./Register.css"
import {  Form, message, Input,  Alert, Button, Card, DatePicker } from "antd";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { MainStoreInjected, instance, withLocalizedForm, clearFieldErrors, extractServerValidationErrors, constructFieldsWithErrors, injectMainStore, CubaAppContextValue, getCubaREST, MultilineText } from "@cuba-platform/react";
import { Customer } from "../../../cuba/entities/tagency_Customer";
import { observable, action, IReactionDisposer, toJS } from "mobx";
import { observer } from "mobx-react";
import { FormComponentProps } from "antd/lib/form";
import { restServices } from "../../../cuba/services";
import moment, { Moment } from "moment";

type Props = FormComponentProps & EditorProps & MainStoreInjected & CubaAppContextValue;

type EditorProps = {
  entityId?: string;
  close: (register: boolean) =>void;
//  show: boolean;
};

@observer
class Register extends React.Component< WrappedComponentProps & Props> {


  registerService = (customer: Customer) => {
    return restServices.tagency_RegisterService.register(getCubaREST()!)({customer: customer});
  }

  dataInstance  = instance<Customer>(Customer.NAME,{view: 'customer-view',loadImmediately: false});
  fields = [
    "firstName",
    "name",
    "lastName",
    "email",
    "phone",
     "birthDay",
    "placeBirth",
    "passport"
  ];
/*
@observable
visible = this.props.show;*/
@observable
globalErrors: string[] = [];
reactionDisposer: IReactionDisposer;
@observable
submited = false
@action
resgister = ()=>{
  this.submited = true
  this.props.form.validateFields((err) => {
    if (err) {
      message.error(
        this.props.intl.formatMessage({
          id: "management.editor.validationError"
        })
      );
      return;
    }
    this.registerService(this.props.form.getFieldsValue(this.fields))
      .then(() => {
        message.success(
          this.props.intl.formatMessage({ id: "register.success" })
        );
        //this.visible =false;
        this.close();
      })
      .catch((e: any) => {
        if (e.response && typeof e.response.json === "function") {
          e.response.json().then((response: any) => {
            clearFieldErrors(this.props.form);
            const {
              globalErrors,
              fieldErrors
            } = extractServerValidationErrors(response);
            this.globalErrors = globalErrors;
            if (fieldErrors.size > 0) {
              this.props.form.setFields(
                constructFieldsWithErrors(fieldErrors, this.props.form)
              );
            }

            if (fieldErrors.size > 0 || globalErrors.length > 0) {
              message.error(
                this.props.intl.formatMessage({
                  id: "management.editor.validationError"
                })
              );
            } else {
              message.error(
                this.props.intl.formatMessage({
                  id: "management.editor.error"
                })
              );
            }
          });
        } else {
          message.error(
            this.props.intl.formatMessage({ id: "management.editor.error" })
          );
        }
        this.submited =false;
      });
  });
};

major: Moment;

notMajor = (date : moment.Moment)=>date.local().isAfter(this.major);

close = ()=>this.props.close(true);

  render() {
    ///store!.item.firstName = "blala";
    const { getFieldDecorator } = this.props.form;
    return(
      //<Modal title={this.props.intl.formatMessage({ id: "register.title" })}
        //visible={this.visible}
        //onCancel ={this.close}
        //onOk = {this.resgister}>
        <Card  title={<FormattedMessage id="register.title" />} className="app-register narrow-layout">
        <Form layout="horizontal" labelAlign="left" onSubmit={this.resgister} >
          <Form.Item  label= {<FormattedMessage id ='customer.firstName'/>} 
                      >
            {getFieldDecorator('firstName')(<Input />)}
          </Form.Item>
          <Form.Item  label= {<FormattedMessage id ='customer.name'/>} 
                      >
            {getFieldDecorator('name',{
                rules: [{ required: true }]
              })(<Input />)}
          </Form.Item>
          <Form.Item  label= {<FormattedMessage id ='customer.lastName'/>} 
                      >
            {getFieldDecorator('lastName')(<Input />)}
          </Form.Item>
            <Form.Item label={<FormattedMessage id ='customer.email'/>}>
              {getFieldDecorator('email',{rules:[{type: 'email'}]})(<Input type='email'/>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id ='customer.phone'/>}>
              {getFieldDecorator('phone',
              {rules:
                [
                  {required: true},
                  {message: <FormattedMessage id='phone.error'/>
                    ,pattern: /(\+243)\d/}
                ]})(<Input type='tel'
                            placeholder="+243"/>)}
            </Form.Item>
            <Form.Item label={<FormattedMessage id ='customer.birthDay'/>}>
              {getFieldDecorator('birthDay',{})(<DatePicker disabledDate={this.notMajor} showToday={false}/>)}
            </Form.Item>
            <Form.Item  label= {<FormattedMessage id ='customer.placeBirth'/>} 
                      >
            {getFieldDecorator('placeBirth')(<Input />)}
          </Form.Item>
          <Form.Item  label= {<FormattedMessage id ='customer.passport'/>} 
                      >
            {getFieldDecorator('passport')(<Input />)}
          </Form.Item>
          {this.globalErrors.length > 0 && (
              <Alert
                message={<MultilineText lines={toJS(this.globalErrors)} />}
                type="error"
                style={{ marginBottom: "24px" }}
              />
            )}
            <Form.Item>

              <Button
                type="default"
                htmlType="button"
                style = {{float: "left"}}
                onClick={this.close}>
                <FormattedMessage id="management.editor.cancel" />
              </Button>
              <Button
                type="primary"
                htmlType="submit"
                loading={this.submited}
                style={{ marginLeft: "8px",float: 'right' }}
              >
                <FormattedMessage id="management.editor.submit" />
              </Button>
            </Form.Item>
        </Form>
        </Card>);
      //</Modal>);

  }
  componentDidMount() {
    this.major = moment().subtract(18,'years');
    console.debug(this.major);
  }
/*
  componentDidMount() {
      this.dataInstance.setItem(new Customer());
    
    this.reactionDisposer = reaction(
      () => {
        return this.dataInstance.item;
      },
      () => {
        this.props.form.setFieldsValue(
          this.dataInstance.getFieldValues(this.fields)
        );
      }
    );
  }

  componentWillUnmount() {
    this.reactionDisposer();
  }*/
}

export default injectMainStore(injectIntl(
  withLocalizedForm<EditorProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(Register))
);