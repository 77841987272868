import * as React from "react";
import { getCubaREST, EntityProperty } from "@cuba-platform/react";
import { Customer } from "../../cuba/entities/tagency_Customer";
import { observer } from "mobx-react";
import { observable, action } from "mobx";
import { SerializedEntityProps } from "@cuba-platform/rest";
import { Card, message } from "antd";
import { FormattedMessage, WrappedComponentProps, injectIntl } from "react-intl";
import { restQueries } from "../../cuba/queries";
@observer
class Information extends React.Component<WrappedComponentProps> {


  customer: Customer;

  fields = ['code',
  'firstName','name','lastName','email','phone','passport','registerDate','marketer','birthDay','placeBirth'];

  @observable
  loading = true;

  @observable
  error = false;

  data: SerializedEntityProps;

  render() {
      if (this.error === true)
      {
        message.error(this.props.intl.formatMessage({id: "information.failed"}));
        return null;
       }/*
      if (this.loading === true) {
        return <Icon type="spin" />
      }*/
      const {loading} = this; 
      return (
            <Card className="narrow-layout" loading={this.loading} title={<FormattedMessage id="information.title" />}>
              {!this.loading &&
              this.fields.map(p => (
                <EntityProperty
                  entityName={Customer.NAME}
                  propertyName={p}
                  value={this.data[p]}
                  key={p}
                />
              ))}
            </Card>);
    }

  componentDidMount()
  {
    restQueries
      .Customer
        .loadCustomerInformation(getCubaREST()!)()
          .then(action((values: any)=>{
            this.data = values[0];
            this.loading = false;
          }))
          .catch(action(()=>{this.error=true}));    
  }
}

export default injectIntl(Information);
