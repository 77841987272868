import { Button, Modal } from "antd";
import * as React from "react";
import { observer } from "mobx-react";
import "./AppHeader.css";
import logo from "./logo.png";
import { injectMainStore, MainStoreInjected, getCubaREST } from "@cuba-platform/react";
import { LanguageSwitcher } from "../../i18n/LanguageSwitcher";
import { injectIntl, WrappedComponentProps } from "react-intl";
import { restServices } from "../../cuba/services";
import { observable, action } from "mobx";
import Password from "./password/Password";


@injectMainStore
@observer
class AppHeader extends React.Component<
  MainStoreInjected & WrappedComponentProps
> {

  @observable
userName :string;
 
  render() {
    const appState = this.props.mainStore!;
    return (
      <div className="app-header">
        <div style={{textAlign: "center", verticalAlign: "middle"}}>
          <img className="app-logo"
            src={logo}
            alt={this.props.intl.formatMessage({ id: "common.alt.logo" })}
          />
          <span className="app-user">{this.userName!}</span>
        </div>
        <div className="user-panel">
          <LanguageSwitcher className="panelelement language-switcher -header" />
          <span className="panelelement" style={{display: "none"}}>{appState.userName}</span>
          <Password/>
          <Button
            className="panelelement"
            ghost={true}
            icon="logout"
            style={{ border: 0 }}
            onClick={this.showLogoutConfirm}
          />
        </div>
      </div>
    );
  }


  showLogoutConfirm = () => {
    Modal.confirm({
      title: this.props.intl.formatMessage({ id: "header.logout.areYouSure" }),
      okText: this.props.intl.formatMessage({ id: "header.logout.ok" }),
      cancelText: this.props.intl.formatMessage({ id: "header.logout.cancel" }),
      onOk: () => {
        this.props.mainStore!.logout();
      }
    });
  };

  componentDidMount()
  {
    restServices.tagency_RegisterService.getUserName(getCubaREST()!)()
    .then(action((name)=>this.userName = name as string));
  }
}

export default injectIntl(AppHeader);
