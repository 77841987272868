import { StandardEntity } from "./base/sys$StandardEntity";
import { Service } from "./tagency_Service";
export class Price extends StandardEntity {
  static NAME = "tagency_Price";
  service?: Service | null;
  amount?: any | null;
  name?: string | null;
  description?: string | null;
}
export type PriceViewName = "_minimal" | "_local" | "_base" | "price-view";
export type PriceView<V extends PriceViewName> = V extends "_minimal"
  ? Pick<Price, "id" | "service" | "name">
  : V extends "_local"
  ? Pick<Price, "id" | "amount" | "name" | "description">
  : V extends "_base"
  ? Pick<Price, "id" | "service" | "name" | "amount" | "description">
  : V extends "price-view"
  ? Pick<Price, "id" | "amount" | "name" | "description" | "service">
  : never;
