import * as React from "react";
import { RouteComponentProps } from "react-router";
import { observer } from "mobx-react";
import TransctionEdit from "./TransctionEdit";
import TransctionList from "./TransctionList";

type Props = RouteComponentProps<{ entityId?: string }>;

@observer
export class TransctionManagement extends React.Component<Props> {
  static PATH = "/transctionManagement";
  static NEW_SUBPATH = "new";

  render() {
    const { entityId } = this.props.match.params;
    return (
      <>
        {entityId ? <TransctionEdit entityId={entityId} /> : <TransctionList />}
      </>
    );
  }
}
