import * as React from "react";
import { FormEvent } from "react";
import { Alert, Button, Card, Form, message } from "antd";
import { observer } from "mobx-react";
import { TransctionManagement } from "./TransctionManagement";
import { FormComponentProps } from "antd/lib/form";
import { Link, Redirect } from "react-router-dom";
import { IReactionDisposer, observable, reaction, toJS } from "mobx";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";

import {
  collection,
  Field,
  instance,
  withLocalizedForm,
  extractServerValidationErrors,
  constructFieldsWithErrors,
  clearFieldErrors,
  MultilineText,
  EntityProperty
} from "@cuba-platform/react";

import "../../app/App.css";
import { Transction } from "../../cuba/entities/tagency_Transction";



type Props = EditorProps;

type EditorProps = {
  entityId: string;
};

@observer
class TransctionEditComponent extends React.Component<
  Props & WrappedComponentProps
> {
  dataInstance = instance<Transction>(Transction.NAME, {
    view: "transction-view",
    loadImmediately: false
  });



  fields = [
    "reference",
    'booking',

    "label",

    "description",

    "type",

    "service",
    "amount",

    "deadline",


    "marketer"
  ];



  render() {

    const {item } = this.dataInstance;
    const loading = item == undefined;
    return (
      <Card className="narrow-layout" loading={loading}>
        <>{!loading&& this.fields.map(p => (
                <EntityProperty
                  entityName={Transction.NAME}
                  propertyName={p}
                  value={item![p]}
                  key={p}
                />
              ))}
              </>
          <div style={{textAlign: "center"}}>
            <Link to={TransctionManagement.PATH}>
              <Button htmlType="button">
                <FormattedMessage id="management.editor.close" />
              </Button>
            </Link>
            </div>
      </Card>
    );
  }

  componentDidMount() {
    if (this.props.entityId !== TransctionManagement.NEW_SUBPATH) {
      this.dataInstance.load(this.props.entityId);
    } 
}
}

export default injectIntl(TransctionEditComponent);
