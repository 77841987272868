import * as React from "react";

import { observable } from "mobx";

import { observer } from "mobx-react";

import { Modal, Button,message } from "antd";

import { Booking } from "../../cuba/entities/tagency_Booking";
import { Link } from "react-router-dom";

import {
  collection,
  injectMainStore,
  MainStoreInjected,
  DataTable,
  getCubaREST
} from "@cuba-platform/react";

import { SerializedEntity } from "@cuba-platform/rest";
import { BookingManagement } from "./BookingManagement";
import {
  FormattedMessage,
  injectIntl,
  WrappedComponentProps
} from "react-intl";
import { BookingStatus } from "../../cuba/enums/enums";

@injectMainStore
@observer
class BookingListComponent extends React.Component<
  MainStoreInjected & WrappedComponentProps
> {
  dataCollection = collection<Booking>(Booking.NAME, {
    view: "booking-customer-view",
    sort: "-createTs"
  });

  fields = ["createTs",'code','reason',"service", "branch","status"];

  @observable selectedRowKey: string | undefined;

  showDeletionDialog = (e: SerializedEntity<Booking>) => {
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "management.browser.delete.areYouSure" },
        { instanceName: e._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "management.browser.delete.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "management.browser.delete.cancel"
      }),
      onOk: () => {
        this.selectedRowKey = undefined;

        return this.dataCollection.delete(e);
      }
    });
  };

isSelectedDraft = ():boolean =>{
  return this.selectedRowKey!= undefined && this.getRecordById(this.selectedRowKey!).status === BookingStatus.DRAFT;
}


  reserve = () => {
    let booking = this.getRecordById(this.selectedRowKey!);
    Modal.confirm({
      title: this.props.intl.formatMessage(
        { id: "booking.submit.areYouSure" },
        { instanceName: booking._instanceName }
      ),
      okText: this.props.intl.formatMessage({
        id: "booking.submit.areYouSure.ok"
      }),
      cancelText: this.props.intl.formatMessage({
        id: "booking.submit.areYouSure.cancel"
      }),
      onOk: () => {
        return getCubaREST()!.commitEntity(Booking.NAME,{id: booking.id, status: BookingStatus.SUBMITTED})
        .then((book: any)=>{
          message.success(
            this.props.intl.formatMessage({ id: "booking.success" })
          );
          this.dataCollection.load();
          }
        )
        .catch(() => {
          message.error(
            this.props.intl.formatMessage({ id: "booking.error" }));
        });
  }
});
}

  render() {

    const buttons = [
      <Link
        to={BookingManagement.PATH + "/edit/" + BookingManagement.NEW_SUBPATH}
        key="create"
      >
        <Button
          htmlType="button"
          style={{ margin: "0 12px 12px 0" }}
          type="primary"
          icon="plus"
        >
          <span>
            <FormattedMessage id="management.browser.create" />
          </span>
        </Button>
      </Link>,
      <Link to={BookingManagement.PATH + "/edit/" + this.selectedRowKey} key="edit">
        <Button
          htmlType="button"
          style={{ margin: "0 12px 12px 0" }}
          disabled={!this.isSelectedDraft()}
          type="default"
        >
          <FormattedMessage id="management.browser.edit" />
        </Button>
      </Link>,
      <Link to={BookingManagement.PATH + "/view/" + this.selectedRowKey} key="open">
      <Button
        htmlType="button"
        style={{ margin: "0 12px 12px 0" }}
        disabled={!this.selectedRowKey}
        type="default"
      >
        <FormattedMessage id="management.browser.open" />
      </Button>
    </Link>,
      <Button
        htmlType="button"
        style={{ margin: "0 12px 12px 0" }}
        disabled={!this.isSelectedDraft()!}
        type="primary"
        onClick = {this.reserve}
      >
        <FormattedMessage id="booking.submit" />
      </Button>,
      <Button
        htmlType="button"
        style={{ margin: "0 12px 12px 0" }}
        disabled={!this.isSelectedDraft() }
        onClick={this.deleteSelectedRow}
        key="remove"
        type="default"
      >
        <FormattedMessage id="management.browser.remove" />
      </Button>
    ];

    return (
      <DataTable
        dataCollection={this.dataCollection}
        fields={this.fields}
        onRowSelectionChange={this.handleRowSelectionChange}
        hideSelectionColumn={true}
        buttons={buttons}
      />
    );
  }

  getRecordById(id: string): SerializedEntity<Booking> {
    const record:
      | SerializedEntity<Booking>
      | undefined = this.dataCollection.items.find(record => record.id === id);

    if (!record) {
      throw new Error("Cannot find entity with id " + id);
    }

    return record;
  }

  handleRowSelectionChange = (selectedRowKeys: string[]) => {
    this.selectedRowKey = selectedRowKeys[0];
  };

  deleteSelectedRow = () => {
    this.showDeletionDialog(this.getRecordById(this.selectedRowKey!));
  };
}

const BookingList = injectIntl(BookingListComponent);

export default BookingList;
