import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import openLauncher from './../../../assets/launcher_button.svg';
import close from './../../../assets/clear-button.svg';
import Badge from './components/Badge';
import './style.scss';
import { Bounce, Zoom } from "react-awesome-reveal";

/*
const Button = ({ toggle, chatOpened, badge }) =>
(<button type="button" className={chatOpened ? 'rcw-launcher rcw-hide-sm' : 'rcw-launcher'} onClick={toggle}>
  <Badge badge={badge} />
  {chatOpened ?
    <img src={close} className="rcw-close-launcher" alt="" /> :
    <img src={openLauncher} className="rcw-open-launcher" alt="" />
  }
</button>)
/*
const Launcher = ({ toggle, chatOpened, badge }) =>
  <Button toggle={toggle} chatOpened={chatOpened} badge={badge}/>
*/

const Launcher = ({ toggle, chatOpened, badge}) =>
  <Bounce>
    <button type="button" className={chatOpened ? 'rcw-launcher rcw-hide-sm' : 'rcw-launcher'} onClick={toggle}>
  <Badge badge={badge} />
  {chatOpened ?
    <img src={close} className="rcw-close-launcher" alt="" /> :
    <img src={openLauncher} className="rcw-open-launcher" alt="" />
  }
</button>
  </Bounce>
  //if (pulse>0)
  //{
  //  <Bounce >
  //  <Button toggle={toggle} chatOpened={chatOpened} badge={badge}/>
  //</Bounce>
  //}
  /*else
  {
    return <Button toggle={toggle} chatOpened={chatOpened} badge={badge}/>;
  }*/

Launcher.propTypes = {
  toggle: PropTypes.func,
  chatOpened: PropTypes.bool,
  badge: PropTypes.number,
  //pulse: PropTypes.number
};

export default connect(store => ({
  chatOpened: store.behavior.get('showChat'),
  //pulse: store.behavior.get('pulse')
}))(Launcher);
