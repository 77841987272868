import { CubaApp, FetchOptions } from "@cuba-platform/rest";

export var restQueries = {
  Customer: {
    loadCustomerInformation: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.query(
        "tagency_Customer",
        "loadCustomerInformation",
        {},
        fetchOpts
      );
    },
    loadCustomerInformationCount: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.queryCount(
        "tagency_Customer",
        "loadCustomerInformation",
        {},
        fetchOpts
      );
    },
    loadCustomerInformationWithCount: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.queryWithCount(
        "tagency_Customer",
        "loadCustomerInformation",
        {},
        fetchOpts
      );
    }
  },
  Transction: {
    loadCustomerTransaction: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.query(
        "tagency_Transction",
        "loadCustomerTransaction",
        {},
        fetchOpts
      );
    },
    loadCustomerTransactionCount: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.queryCount(
        "tagency_Transction",
        "loadCustomerTransaction",
        {},
        fetchOpts
      );
    },
    loadCustomerTransactionWithCount: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.queryWithCount(
        "tagency_Transction",
        "loadCustomerTransaction",
        {},
        fetchOpts
      );
    }
  },
  Price: {
    loadPrices: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.query("tagency_Price", "loadPrices", {}, fetchOpts);
    },
    loadPricesCount: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.queryCount("tagency_Price", "loadPrices", {}, fetchOpts);
    },
    loadPricesWithCount: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.queryWithCount(
        "tagency_Price",
        "loadPrices",
        {},
        fetchOpts
      );
    }
  }
};
