import React from 'react';
import { Provider } from 'react-redux';

import Widget from './components/widget/index';
import { observer } from 'mobx-react';

type func = (...args: any[])=>any;

type ConnectedWidgetProps = 
{
  data: any;
  title?: string,
  titleAvatar?: string,
  subtitle?: any,
  handleNewUserMessage: func,
  handleQuickButtonClicked?: func,
  senderPlaceHolder?: string,
  profileAvatar?: string,
  showCloseButton?: boolean,
  fullScreenMode?: boolean,
  badge?: number,
  autofocus?: boolean,
  launcher?: func,
  store: any
};
@observer
class ConnectedWidget extends React.Component<ConnectedWidgetProps >
{
 
render()
{return (
  <Provider store={this.props.store}>
    <Widget
      title={this.props.title}
      titleAvatar={this.props.titleAvatar}
      subtitle={this.props.data.subtitle}
      handleNewUserMessage={this.props.handleNewUserMessage}
      handleQuickButtonClicked={this.props.handleQuickButtonClicked}
      senderPlaceHolder={this.props.senderPlaceHolder}
      profileAvatar={this.props.profileAvatar}
      showCloseButton={this.props.showCloseButton}
      fullScreenMode={this.props.fullScreenMode}
      badge={this.props.data.badge}
      autofocus={this.props.autofocus}
      customLauncher={this.props.launcher}
    />
  </Provider>);
}


}

export default  ConnectedWidget;
