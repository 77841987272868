import { StandardEntity } from "./base/sys$StandardEntity";
import { Customer } from "./tagency_Customer";
import { Service } from "./tagency_Service";
import { Transction } from "./tagency_Transction";
import { BookingStatus } from "../enums/enums";
import { Branch } from "./tagency_Branch";
export class Booking extends StandardEntity {
  static NAME = "tagency_Booking";
  code?: string | null;
  customer?: Customer | null;
  service?: Service | null;
  transaction?: Transction | null;
  reason?: string | null;
  information?: string | null;
  status?: BookingStatus | null;
  branch?: Branch | null;
}
export type BookingViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "booking-customer-view"
  | "booking-browse";
export type BookingView<V extends BookingViewName> = V extends "_local"
  ? Pick<Booking, "id" | "code" | "reason" | "information" | "status">
  : V extends "_base"
  ? Pick<Booking, "id" | "code" | "reason" | "information" | "status">
  : V extends "booking-customer-view"
  ? Pick<
      Booking,
      | "id"
      | "code"
      | "reason"
      | "information"
      | "status"
      | "service"
      | "branch"
      | "createTs"
      | "transaction"
    >
  : V extends "booking-browse"
  ? Pick<
      Booking,
      | "id"
      | "code"
      | "reason"
      | "information"
      | "status"
      | "service"
      | "branch"
      | "createTs"
      | "transaction"
      | "customer"
    >
  : never;
