import * as React from "react";

import "./Password.css"
import { Modal, Form, message,  Input,  Button } from "antd";
import { injectIntl, WrappedComponentProps, FormattedMessage } from "react-intl";
import { withLocalizedForm,  clearFieldErrors, extractServerValidationErrors, constructFieldsWithErrors, injectMainStore, getCubaREST } from "@cuba-platform/react";
import { observable, action} from "mobx";
import { observer } from "mobx-react";
import { FormComponentProps } from "antd/lib/form";
import { restServices } from "../../../cuba/services";

type Props = FormComponentProps & PasswordProps;

type PasswordProps = {show ?: boolean};

@observer
class Password extends React.Component<WrappedComponentProps & Props> {


  changeService = (oldPwd: string, newPwd: string) => {
    return restServices.tagency_RegisterService.changePassword(getCubaREST()!)({ current: oldPwd, password: newPwd });
  }



  @observable
  requested = false;
  @observable
  globalErrors: string[] = [];


  @action
  change = () => {
    const form = this.props.form;
    this.requested = true;
    form.validateFieldsAndScroll((err) => {
      if (err) {
        message.error(
          this.props.intl.formatMessage({
            id: "management.editor.validationError"
          })
        );
        this.requested = false;
        return;
      }
      if (form.getFieldValue('new') === form.getFieldValue('old')) {
        message.error(
          this.props.intl.formatMessage({
            id: "password.same"
          })
        );
        this.requested = false;
        return;
      }
      if (form.getFieldValue('new') !== form.getFieldValue('confirm')) {
        message.error(
          this.props.intl.formatMessage({
            id: "password.different"
          })
        );
        this.requested = false;
        return;
      }
      this.changeService(this.props.form.getFieldValue('old'), this.props.form.getFieldValue('new'))
        .then(() => {
          message.success(
            this.props.intl.formatMessage({ id: "password.success" })
          );
          this.closeDialog();
        })
        .catch((e: any) => {
          if (e.response && typeof e.response.json === "function") {
            e.response.json().then((response: any) => {
              clearFieldErrors(this.props.form);
              const {
                globalErrors,
                fieldErrors
              } = extractServerValidationErrors(response);
              this.globalErrors = globalErrors;
              if (fieldErrors.size > 0) {
                this.props.form.setFields(
                  constructFieldsWithErrors(fieldErrors, this.props.form)
                );
              }

              if (fieldErrors.size > 0 || globalErrors.length > 0) {
                message.error(
                  this.props.intl.formatMessage({
                    id: "management.editor.validationError"
                  })
                );
              } else {
                message.error(
                  this.props.intl.formatMessage({
                    id: response.details
                  })
                );
              }
            });
          } else {
            message.error(
              this.props.intl.formatMessage({ id: "management.editor.error" })
            );
          }
          this.requested = false;
        });
    });
  };

  @observable
  show = false

  @action
  showDialog = () => {
    this.show = true;
  }

  @action
  closeDialog = () => this.show = false

  render() {
    const { getFieldDecorator } = this.props.form;
      return (<>
        <Button className="panelelement password" 
        icon='security-scan' 
        onClick={this.showDialog} 
        style ={{ border: 0 }}
        htmlType="button" 
        ghost={true} 
        title={this.props.intl.formatMessage({ id: 'password.title'})} >
        </Button>
        {this.show && <Modal visible={this.show} title={this.props.intl.formatMessage({id : 'password.title'}) }
        width="300px"
        onCancel={this.closeDialog} 
        onOk={this.change}
        okText={<FormattedMessage id="password.change"/>}
        cancelText={<FormattedMessage id="management.editor.cancel"/>}>
          <Form  layout="vertical" >
          <Form.Item  label= {<FormattedMessage id ='password.old'/>} style={{marginBottom: "0px"}}>
            {getFieldDecorator('old',{
                rules: [{ required: true }]
              })(<Input type="password" />)}
          </Form.Item>
          <Form.Item  label= {<FormattedMessage id ='password.new'/>} style={{marginBottom: "0px"}} >
            {getFieldDecorator('new',{
                rules: [{ required: true }]
              })(<Input type="password"/>)}
          </Form.Item>
          <Form.Item  label= {<FormattedMessage id ='password.confirm'/>} style={{marginBottom: "0px"}} >
            {getFieldDecorator('confirm',{
                rules: [{ required: true }]
              })(<Input type="password"/>)}
          </Form.Item>
          </Form>
          </Modal>}
      </>
    );

  }
}
  
export default injectMainStore(injectIntl(
  withLocalizedForm<PasswordProps>({
    onValuesChange: (props: any, changedValues: any) => {
      // Reset server-side errors when field is edited
      Object.keys(changedValues).forEach((fieldName: string) => {
        props.form.setFields({
          [fieldName]: {
            value: changedValues[fieldName]
          }
        });
      });
    }
  })(Password))
);

/*
{this.show &&
          <Modal title={<FormattedMessage id="password.title" />}
            onCancel={this.closeDialog}
            okButtonProps={{ loading: this.requested }}
            cancelText={<FormattedMessage id="password.cancel" />}
            okText={<FormattedMessage id="password.change" />}
            onOk={this.change}>
            <Form layout="horizontal" labelAlign="left" >
              <Form.Item
                style={{ marginBottom: "12px" }} label={<FormattedMessage id="password.old" />}>
                {getFieldDecorator('old', {
                  rules: [{ required: true}]
                })(<Input size="large" />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id="password.new" />}>
                {getFieldDecorator('new', {
                  rules: [{ required: true,min: 5}]
                })(<Input size="large" />)}
              </Form.Item>
              <Form.Item label={<FormattedMessage id="password.confirm" />}>
                {getFieldDecorator('confirm', {
                  rules: [{ required: true,min: 5}]
                })(<Input size="large" />)}
              </Form.Item>
              {this.globalErrors.length > 0 && (
                <Alert
                  message={<MultilineText lines={toJS(this.globalErrors)} />}
                  type="error"
                  style={{ marginBottom: "24px" }}
                />
              )}

            </Form>
          </Modal>}
          */
