import en from "./en.json";
//import ru from "./ru.json";
import fr from "./fr.json";
import enUs from "antd/es/locale/en_US";
//import ruRu from "antd/es/locale/ru_RU";
import frFr from "antd/es/locale/fr_FR";

export const antdLocaleMapping = {
  en: enUs,
  //ru: ruRu
  fr : frFr
};

export const messagesMapping = {
  en: en,
  //ru: ru
  fr: fr
};
