import { PriceCards } from "./app/price/PriceCards";
import { TransctionManagement } from "./app/transaction/TransctionManagement";
import { BookingManagement } from "./app/booking/BookingManagement";
//import { Powered } from "./app/powered/Powered";
import { getMenuItems } from "@cuba-platform/react";
import Information from "./app/information/Information";

export const menuItems = getMenuItems();

// Code below demonstrates how we can create SubMenu section
// Remove '/*' '*/' comments and restart app to get this block in menu

/*
// This is RouteItem object that we want to see in User Settings sub menu
const backToHomeRouteItem = {
  pathPattern: "/backToHome",
  menuLink: "/",
  component: null,
  caption: "home"
};
// SubMenu object
const userSettingsSubMenu = {
  caption: 'UserSettings', // add router.UserSettings key to src/i18n/en.json for valid caption
  items: [backToHomeRouteItem]};
// Add sub menu item to menu config
menuItems.push(userSettingsSubMenu);
*/

menuItems.push({
  pathPattern: "/information",
  menuLink: "/information",
  component: Information,
  caption: "Information"
});

menuItems.push({
  pathPattern: "/bookingManagement/:action?/:entityId?",
  menuLink: "/bookingManagement",
  component: BookingManagement,
  caption: "BookingManagement"
});

menuItems.push({
  pathPattern: "/transctionManagement/:entityId?",
  menuLink: "/transctionManagement",
  component: TransctionManagement,
  caption: "TransctionManagement"
});

menuItems.push({
  pathPattern: "/priceCards",
  menuLink: "/priceCards",
  component: PriceCards,
  caption: "PriceCards"
});


