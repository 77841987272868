import { CubaApp, FetchOptions } from "@cuba-platform/rest";

export type tagency_RegisterService_register_params = {
  customer: any;
};

export type tagency_RegisterService_resetPassword_params = {
  login: any;
};

export type tagency_RegisterService_changePassword_params = {
  current: string;
  password: string;
};

export type tagency_ChatService_loadMessages_params = {
  start: any;
  groupId: any;
  limit: any;
};

export type tagency_ChatService_markAsRead_params = {
  messageIds: any;
};

export type tagency_ChatService_sendMessage_params = {
  groupId: any;
  content: any;
};

export type tagency_ChatService_getUnreadCountMessage_params = {
  groupId: any;
};

export type tagency_ChatService_loadNewMessages_params = {
  groupId: any;
  limit: any;
};

export var restServices = {
  tagency_RegisterService: {
    register: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_RegisterService_register_params
    ) => {
      return cubaApp.invokeService(
        "tagency_RegisterService",
        "register",
        params,
        fetchOpts
      );
    },
    resetPassword: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_RegisterService_resetPassword_params
    ) => {
      return cubaApp.invokeService(
        "tagency_RegisterService",
        "resetPassword",
        params,
        fetchOpts
      );
    },
    getUserName: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => () => {
      return cubaApp.invokeService(
        "tagency_RegisterService",
        "getUserName",
        {},
        fetchOpts
      );
    },
    changePassword: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_RegisterService_changePassword_params
    ) => {
      return cubaApp.invokeService(
        "tagency_RegisterService",
        "changePassword",
        params,
        fetchOpts
      );
    }
  },
  tagency_ChatService: {
    loadMessages: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_ChatService_loadMessages_params
    ) => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "loadMessages",
        params,
        fetchOpts
      );
    },
    markAsRead: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_ChatService_markAsRead_params
    ) => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "markAsRead",
        params,
        fetchOpts
      );
    },
    sendMessage: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_ChatService_sendMessage_params
    ) => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "sendMessage",
        params,
        fetchOpts
      );
    },
    getUnreadCountMessage: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_ChatService_getUnreadCountMessage_params
    ) => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "getUnreadCountMessage",
        params,
        fetchOpts
      );
    },
    getConnectedCustomerGroup: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "getConnectedCustomerGroup",
        {},
        fetchOpts
      );
    },
    loadNewMessages: (cubaApp: CubaApp, fetchOpts?: FetchOptions) => (
      params: tagency_ChatService_loadNewMessages_params
    ) => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "loadNewMessages",
        params,
        fetchOpts
      );
    },
    isConnectedCustomerMarketerOnline: (
      cubaApp: CubaApp,
      fetchOpts?: FetchOptions
    ) => () => {
      return cubaApp.invokeService(
        "tagency_ChatService",
        "isConnectedCustomerMarketerOnline",
        {},
        fetchOpts
      );
    }
  }
};
