import { StandardEntity } from "./base/sys$StandardEntity";
import { Account } from "./tagency_Account";
import { User } from "./tagency$User";
export class Customer extends StandardEntity {
  static NAME = "tagency_Customer";
  code?: string | null;
  firstName?: string | null;
  name?: string | null;
  lastName?: string | null;
  email?: string | null;
  phone?: string | null;
  registerDate?: any | null;
  status?: string | null;
  account?: Account | null;
  previousStatus?: string | null;
  marketer?: User | null;
  birthDay?: any | null;
  passport?: string | null;
  placeBirth?: string | null;
  registered?: boolean | null;
  user?: User | null;
}
export type CustomerViewName =
  | "_minimal"
  | "_local"
  | "_base"
  | "customer-view"
  | "customer-list";
export type CustomerView<V extends CustomerViewName> = V extends "_minimal"
  ? Pick<Customer, "id" | "firstName" | "name" | "lastName" | "code">
  : V extends "_local"
  ? Pick<
      Customer,
      | "id"
      | "code"
      | "firstName"
      | "name"
      | "lastName"
      | "email"
      | "phone"
      | "registerDate"
      | "status"
      | "previousStatus"
      | "birthDay"
      | "passport"
      | "placeBirth"
      | "registered"
    >
  : V extends "_base"
  ? Pick<
      Customer,
      | "id"
      | "firstName"
      | "name"
      | "lastName"
      | "code"
      | "email"
      | "phone"
      | "registerDate"
      | "status"
      | "previousStatus"
      | "birthDay"
      | "passport"
      | "placeBirth"
      | "registered"
    >
  : V extends "customer-view"
  ? Pick<
      Customer,
      | "id"
      | "code"
      | "firstName"
      | "name"
      | "lastName"
      | "email"
      | "phone"
      | "registerDate"
      | "status"
      | "previousStatus"
      | "birthDay"
      | "passport"
      | "placeBirth"
      | "registered"
      | "marketer"
      | "account"
    >
  : V extends "customer-list"
  ? Pick<
      Customer,
      | "id"
      | "firstName"
      | "name"
      | "lastName"
      | "code"
      | "email"
      | "phone"
      | "status"
      | "marketer"
    >
  : never;
