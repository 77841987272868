import { Map } from 'immutable';

import { createReducer } from '../../utils/store';

import * as actionTypes from '../actions/actionTypes';

const initialState = Map({ showChat: false, disabledInput: false, msgLoader: false ,pulse: 0,disabledButton: true});

const behaviorReducer = {
  [actionTypes.TOGGLE_CHAT]: state =>
    state.update('showChat', showChat => !showChat),

    [actionTypes.NOTIFIY_CONNECTED]: state =>
    state.update('pulse', pulse => pulse+1),

    [actionTypes.NOTIFIY_DISCONNECTED]: state =>
    state.update('pulse', value => 0),

  [actionTypes.TOGGLE_INPUT_DISABLED]: state =>
    state.update('disabledInput', disabledInput => !disabledInput),

  [actionTypes.TOGGLE_BUTTON_DISABLED]: state =>
    state.update('disabledButton', disabledButton => !disabledButton),

  [actionTypes.ENABLE_INPUT]: state =>
    state.update('disabledInput', disabledInput => false),

  [actionTypes.DISABLE_INPUT]: state =>
    state.update('disabledInput', disabledInput => true),
  [actionTypes.TOGGLE_MSG_LOADER]: state =>
    state.update('msgLoader', msgLoader => !msgLoader)
};

export default (state = initialState, action) => createReducer(behaviorReducer, state, action);
